<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:calc(100vh - 52px)" @resized="setTableHeight" v-resize="setTableHeight">
			<pane min-size="25" size="40" ref="toppane">
				<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
					:options.sync="options" :server-items-length="totalItems"
					:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header :height="tableheight"
					:loading="loading" loading-text="加载中..." show-select single-select>
					<template v-slot:top>
						<div class="d-flex flex-wrap pl-2 pb-2" style="gap:48px" ref="tabletopbar">
							<v-switch label="仅显示有病历" v-model="only" inset hide-details style="flex: 0 1 auto" @change="loadData"/>
							<v-text-field label="姓名" v-model.trim="pname" hide-details clearable style="flex: 0 1 auto"
								append-icon="search" @click:append="loadData" @keyup.enter.native="loadData"/>
						</div>
						<v-divider/>
					</template>
					<template v-slot:item.age="{ item }">
						{{getAge(item)}}
					</template>
				</v-data-table>
			</pane>
			<pane min-size="20" size="60" v-if="(selected.length > 0)">
				<div style="max-height: 100%" class="overflow-y-auto">
					<div>
						<v-btn class="ml-8 mt-6 mb-4" rounded width="120" color="primary" @click.stop="addRecord">添加病历</v-btn>
						<diagnose-history :items="MedicalHistory" @edit="editRecord" @remove="removeRecord"/>
					</div>
				</div>
			</pane>
		</splitpanes>
		<diagnose v-model="diagnose" :patient="selected[0]" :mrindex="mrindex" @change="fetchMr"/>
	</div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    import {formatTime, calculateAge} from '../utils'
    import { Splitpanes, Pane } from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'
	import DiagnoseHistory from '../components/DiagnoseHistory.vue'
	import Diagnose from '../components/Diagnose.vue'

    export default {
        data() {
            return {
                authed: false,
                headers: [
                    {text:'病案号', value:'pid', width:100},
                    {text:'姓名', value:'pii.username', width:120},
                    {text:'性别', value:'pii.gender', width:80},
                    {text:'电话', value:'pii.phone', width:100},
                    {text:'证件号', value:'pii.idcard', width:120},
                    {text:'年龄', value:'age', width:80},
                    {text:'健康顾问', value:'consultantName', width:100},
				],
                items: [],
                loading: false,
				selected: [],
				options: {sortBy:['pid'], sortDesc:[false]},
				totalItems: 0,
				tableheight: undefined,
				diagnose: false,
				mrindex: -1,
				MedicalHistory: [],
				pname: '',
				only: false,
            }
        },
		created() {
			this.formatTime = formatTime;
		},
        mounted() {
			this.authed = this.$hasPrivilege(['病历管理']);
        },
        methods: {
			setTableHeight() {
				if (!this.$vuetify.breakpoint.mobile && this.selected.length === 0) {
					this.tableheight = undefined;
					return;
				}
				const tableFooterHeight = 36;
				setTimeout(() => {
					const ttbh = this.$refs.tabletopbar.getBoundingClientRect().height;
					const paneHeight = this.$refs.toppane.$el.getBoundingClientRect().height;
					this.tableheight = paneHeight - tableFooterHeight - ttbh;
					if (this.selected.length > 0) this.$nextTick(() => this.$vuetify.goTo(".v-data-table__selected", { container: ".v-data-table__wrapper" }) );
				}, 200)
			},
            getAge(item) {
                return calculateAge(item.pii.dob);
            },
			async fetchMr() {
				this.MedicalHistory = [];
				const db = this.$tcbapp.database();
				const _ = db.command;
				try {
					const res = await db.collection('wp2order')
						.where({
							pid: this.selected[0].pid,
							MedicalHistory: _.exists(true),
						})
						.field({MedicalHistory:true})
						.get();
					const r = res.data.find(x => x._id === this.selected[0]._id);
					if (r) {
						this.selected[0].MedicalHistory = r.MedicalHistory;
					}
					this.MedicalHistory = res.data
						.flatMap(x => {
							x.MedicalHistory.forEach((y, index) => {
								if (x._id === this.selected[0]._id) {
									y.id = x._id;
									y.mrindex = index;
								}
							});
							return x.MedicalHistory;
						})
						.sort((a, b) => a.time - b.time);
				} catch(err) {
					console.error(err);
				}
			},
			async loadData() {
				this.selected = [];
				if (this.options.page !== 1) {
					this.options.page = 1;	//自动触发fetchData
					return;
				}
				await this.fetchData();
			},
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = [];
				if (this.pname) {
					filter = [{'pii.username':this.pname}];
					this.porderid = undefined;
				} else if (this.porderid) {
					filter = [{_id:this.porderid}];
				}
				if (this.only) {
					filter.push({MedicalHistory: _.exists(true)});
				}
				filter.push({hide:_.neq(true)});
				const f = filter;
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			addRecord() {
				this.mrindex = -1;
				this.diagnose = true;
			},
			editRecord(idx) {
				this.mrindex = idx;
				this.diagnose = true;
			},
			async removeRecord(index) {
				const res = await this.$dialog.warning({
					text: '确定要删除此项记录？',
					title: '提示'
				});
				if (!res) return;
				const id = this.selected[0]._id;
				this.loading = true;
				try {
					const res = await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'removeMedicalRecord',
							data: {
								id,
								index,
							}
						}
					});
					if (!res.result.ret) {
						this.$dialog.error({title:'删除失败', text:res.result.msg});
						return;
					}
					this.fetchMr();
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('删除失败');
				} finally {
					this.loading = false;
				}
			},
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) return;
				this.fetchMr();
			}
		},
        components: { Splitpanes, Pane, DiagnoseHistory, Diagnose }
    }
</script>
